import { useState, useEffect } from "react";
import SelettoreProfessionistaPaz from "../SelettoreProfessionistaPaz";
import BackendDataTable from "../BackendDataTable";
import { useContext, useRef } from 'react';
import { ContextGestionale } from "../../App";
import FeatherIcon from "../FeatherIcon";
import APICallButton from "../APICallButton";
import EsitoGenerazioneFattureModal from "../modals/EsitoGenerazioneFattureModal";
import Heading from "../Heading";
import ModalPrompt from "../modals/ModalPrompt";
import PazientiNoFIC from "../PazientiNoFIC";
import SearchAndSelect from "../SearchAndSelect";
import ProgressBar from "../ProgressBar";

function Contabilita(props) {
	const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso } = useContext(ContextGestionale);
	//sono le due modalità della pagina: Se TRUE, viene mostrato ciò che è già fatturato nella fattura selezionata, altrimenti viene mostrato ciò che in quel mese va ancora fatturato
	const [idFatturaSelezionata, setIdFatturaSelezionata] = useState(null);
	const [mostra, setMostra] = useState(loggedUser.tipoUtente == "professionista" ? "Pazienti" : "Tutti");
	const [selectedPaziente, setSelectedPaziente] = useState(undefined);
	const [selectedProfessionista, setSelectedProfessionista] = useState(loggedUser.tipoUtente == "professionista" ? loggedUser.user : undefined);
	const [selectedPazientiConFIC, setSelectedPazientiConFIC] = useState([]);
	const [selectedProfessionistiConFIC, setSelectedProfessionistiConFIC] = useState([]);
	const [pazientiUpdated, setPazientiUpdated] = useState(true);
	const [professionistiUpdated, setProfessionistiUpdated] = useState(true);
	const [leFattureSonoAggiornate, setLeFattureSonoAggiornate] = useState(true);
	const mesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
	const [mese, setMese] = useState(new Date().getUTCMonth() + 1);
	const [anno, setAnno] = useState(new Date().getUTCFullYear());
	const [reqUrlSedute, setReqUrlSedute] = useState("");
	const [reqUrlFatture, setReqUrlFatture] = useState("");
	const [reqUrlFattureAnteprima, setReqUrlFattureAnteprima] = useState("");
	const columnsToExcludeSeduteEffettuate = ["idSeduta", "idPaziente", "idServizio", "idProfessionista", "idCertificazione", "idPrenotazione", "idArea", "nomeArea", "motivo", "modalita", "certificazione", "nomeProfessionista", "cognomeProfessionista", "nomePaziente", "cognomePaziente", "oraInizio", "oraFine", "descrizioneServizio"];
	const columnsToExcludeFatture = ["id", "idDocumentoFattureInCloud", "mese", "anno", "idPaziente", "idProfessionista", "approvata", "idDocumentoFIC", "idFattura", "fatturaDiProva","sollecitoInviato"];
	const pricesColumns = ["prezzo", "costoProfessionista"];
	const [prezziTotaliSedute, setPrezziTotaliSedute] = useState({});
	const [prezziTotaliCertificazioni, setPrezziTotaliCertificazioni] = useState({});
	const [ultimoControllo, setUltimoControllo] = useState(undefined);
	const [listaCertificazioni, setListaCertificazioni] = useState([]);
	const [certificazioniContabilizzate, setCertificazioniContabilizzate] = useState([]);
	const [nessunaCertificazioneContabilizzata, setNessunaCertificazioneContabilizzata] = useState(false);
	const [generaFattureDiProva, setGeneraFattureDiProva] = useState(true);
	const monthsContainer = useRef(null);
	const functionsOnColumnsSedute = [];
	const functionsOnColumnsFatture = [];
	const columnsColorsFunctionsFatture = [];
	const [fattureMesiAnno, setFattureMesiAnno] = useState(new Array(12).fill(0));
	const [importiMesiAnno, setImportiMesiAnno] = useState(new Array(12).fill(0));
	const minMonthWidth = 72; // in pixel
	const [monthWidth, setMonthWidth] = useState(minMonthWidth);
	const [isShowModalGeneraFatture, setShowModalGeneraFatture] = useState(false);
	const [isShowModalCancellaFatture, setShowModalCancellaFatture] = useState(false);
	const [isShowModalEsitoGenerazione, setShowModalEsitoGenerazione] = useState(false);
	const [esitoGenerazioneFatture, setEsitoGenerazioneFatture] = useState([]);
	const [pazientiNoFIC, setPazientiNoFIC] = useState(null);
	const [pazientiConFIC, setPazientiConFIC] = useState([]);
	const [professionistiContabilita, setProfessionistiContabilita] = useState([]);
	const [escludiDisabledPazienti, setEscludiDisabledPazienti] = useState(true);
	const [nonEscludereDisabledPazienti, setNonEscludereDisabledPazienti] = useState(true);
	const [escludiDisabledProfessionisti, setEscludiDisabledProfessionisti] = useState(true);
	const [nonEscludereDisabledProfessionisti, setNonEscludereDisabledProfessionisti] = useState(true);
	const [percentualeCompletamento, setPercentualeCompletamento] = useState(null);
	const [tickIndex, setTickIndex] = useState(undefined);
	const [tickIndexScansioneFatture, setTickIndexScansioneFatture] = useState(null);
	const [percentualeCompletamentoScansioneFatture, setPercentualeCompletamentoScansioneFatture] = useState(0.0);
	const [tabAperta, setTabAperta] = useState(1);
	const [importiRiepilogo, setImportiRiepilogo] = useState([]);
	const [cancellaFattureDiProva, setCancellaFattureDiProva] = useState(true);
	const [cancellaFattureDefinitive, setCancellaFattureDefinitive] = useState(false);
	//la selezione è corretta se è selezionato il radioButton Tutti oppure se è selezionato un paziente o un professionista. La selezione non è corretta quando fai click sul radio button "Professionista" o "Paziente" e non selezioni un paziente o un professionista
	const selezioneCorretta = (mostra == "Tutti" || (mostra == "Pazienti" && selectedPaziente != undefined) || (mostra == "Professionisti" && selectedProfessionista != undefined));
	const resizeTimeout = useRef(undefined);
	const abilitaTab1 = loggedUser.tipoUtente == "segretario";
	const abilitaTab2 = loggedUser.tipoUtente == "segretario";
	const abilitaTab3 = loggedUser.tipoUtente == "professionista";
	const abilitaTab4 = loggedUser.tipoUtente == "professionista" && props.abilitaCertificazioni == true;
	const columnsDataTypesSedute = {
		"prezzo": "price",
		"costoProfessionista": "price",
		"data": "date"
	}
	const columnsDataTypesFatture = {
		"data": "date",
		"dataScadenza": "date",
		"anno": "numeric",
		"numero": "numeric",
		"importo": "price",
		"dataPagamento": "date"
	}
	functionsOnColumnsSedute["modalita"] = (val) => {
		if (val == 0) {
			return "In presenza";
		} else if (val == 1) {
			return "Ibrida";
		} else if (val == 2) {
			return "Online";
		}
	};
	functionsOnColumnsSedute["prezzo"] = (val) => {
		if (val != null && val != undefined) {
			return importoConPuntoEVirgola(val);
		} else {
			return "---";
		}
	};
	functionsOnColumnsSedute["costoProfessionista"] = (val) => {
		if (val != null && val != undefined) {
			return importoConPuntoEVirgola(val);
		} else {
			return "---";
		}
	};
	functionsOnColumnsSedute["effettuata"] = (val) => {
		if (val == null) {
			return "Non segnata";
		} else if (val == 1) {
			return "Sì";
		} else if (val == 0) {
			return "No";
		}
	};
	functionsOnColumnsSedute["data"] = (val) => {
		if (val == null || val == undefined || val == "") {
			return "---";
		}
		return val.substring(8, 10) + "/" + val.substring(5, 7) + "/" + val.substring(0, 4);
	};
	functionsOnColumnsSedute["certificazione"] = (val) => {
		if (val == null) {
			return "---";
		} else {
			return val;
		}
	}
	functionsOnColumnsFatture["data"] = (val) => {
		if (val == null || val == undefined || val == "") {
			return "---";
		}
		return val.substring(8, 10) + "/" + val.substring(5, 7) + "/" + val.substring(0, 4);
	};
	functionsOnColumnsFatture["dataScadenza"] = (val) => {
		if (val == null || val == undefined || val == "") {
			return "---";
		}
		return val.substring(8, 10) + "/" + val.substring(5, 7) + "/" + val.substring(0, 4);
	};
	functionsOnColumnsFatture["importo"] = (val) => {
		if (val != null && val != undefined) {
			return importoConPuntoEVirgola(val);
		} else {
			return "---";
		}
	};
	functionsOnColumnsFatture["totaleFattura"] = (val) => {
		if (val != null && val != undefined) {
			return importoConPuntoEVirgola(val);
		} else {
			return "---";
		}
	};
	functionsOnColumnsFatture["mese"] = (val) => {
		return mesi[val - 1];
	};
	functionsOnColumnsFatture["dataPagamento"] = (val) => {
		return (val == null ? "Non pagata" : dataFormatoItaliano(val));
	}
	columnsColorsFunctionsFatture["dataPagamento"] = (val) => {
		return val == "Non pagata" ? "#ff0000" : undefined;
	}

	useEffect(() => {
		if (pageViewed === pages.Contabilita) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: pagina contabilita aperta 1");
			}
			setPazientiUpdated(false);
			setLeFattureSonoAggiornate(false);
			setProfessionistiUpdated(false);
			setSelectedPaziente(undefined);
			setIdFatturaSelezionata(null);
			setSelectedProfessionista(loggedUser.tipoUtente == "professionista" ? loggedUser.user : undefined);
			setGeneraFattureDiProva(true);
			setMese(new Date().getUTCMonth() + 1);
			setAnno(new Date().getUTCFullYear());
			setMostra(loggedUser.tipoUtente == "professionista" ? "Pazienti" : "Tutti");
			loadCertificazioni();
			loadDataOraUltimoControlloPagamenti();
		}
	}, [pageViewed]);

	useEffect(() => {
		if (tickIndexScansioneFatture != null && tickIndexScansioneFatture != undefined) {
			nodeReq.get(process.env.REACT_APP_API_URL + "/percentualeCompletamentoScansione")
				.then(response => {
					setPercentualeCompletamentoScansioneFatture(response.status == 200 ? response.data.completamento : undefined);
					if (!(response.status == 200 && response.data.completamento == 100)) {
						setTimeout(() => { setTickIndexScansioneFatture(tickIndexScansioneFatture + 1); }, 1000);
					}
				})
				.catch(error => {
					setPercentualeCompletamentoScansioneFatture(undefined);
				});
		}
	}, [tickIndexScansioneFatture]);

	function loadDataOraUltimoControlloPagamenti() {
		if (loggedUser.tipoUtente == "segretario") {
			setLoading(true);
			nodeReq.get(process.env.REACT_APP_API_URL + "/ultimoControlloPagamenti")
				.then(response => {
					if (response.status == 200) {
						setUltimoControllo(response.data.dataOra);
					} else {
						genericAlert("Impossibile conoscere l'ultimo controllo dei pagamenti al momento a causa di un errore. Riprova più tardi.");
					}
				})
				.catch(error => {
					handleAPIError(error, "conoscere l'ultimo controllo dei pagamenti");
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}

	function loadCertificazioni() {
		setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/certificazioni?perPaginaContabilita=true&ordineAlfabetico=true")
			.then(response => {
				if (response.status == 200) {
					setListaCertificazioni(response.data.dbdata);
				} else {
					genericAlert("Impossibile recuperare le certificazioni al momento. Riprova più tardi");
				}
			})
			.catch(error => {
				handleAPIError(error, "recuperare le certificazioni");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	//quando cambia il mese o l'anno selezionato, ricalcola il numero di fatture per ogni mese di quell'anno e anche gli importi

	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: carico numeri e totali fatture per i bottoni dei mesi");
		}
		reloadFattureMesiAnno();
	}, [mese, anno]);


	useEffect(() => {
		if (!leFattureSonoAggiornate) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: carico numeri e totali fatture per i bottoni dei mesi");
			}
			reloadFattureMesiAnno();
		}
	}, [leFattureSonoAggiornate]);

	//quando vengono selezionati il mese, l'anno e la selezione è corretta, chiama l'endpoint degli importi per il riepilogo
	useEffect(() => {
		if (pageViewed === pages.Contabilita && selezioneCorretta) {
			nodeReq.get(process.env.REACT_APP_API_URL + "/riepilogoContabilita?mese=" + mese + "&anno=" + anno + "&idPaziente=" + selectedPaziente + "&idProfessionista=" + (loggedUser.tipoUtente == "professionista" ? loggedUser.user : selectedProfessionista))
				.then(response => {
					if (response.status == 200) {
						setImportiRiepilogo(response.data.dbdata);
					} else {
						genericAlert("Impossibile ottenere il riepilogo al momento a causa di un errore. Riprova più tardi.");
					}
				})
				.catch(error => {
					handleAPIError(error, "ottenere il riepilogo");
				});
		}
	}, [mese, anno, selectedPaziente, selectedProfessionista, mostra, selezioneCorretta, pageViewed]);


	function reloadFattureMesiAnno() {
		if (pageViewed !== pages.Login) {
			setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/fattureMesiAnno?anno=" + anno)
				.then(response => {
					if (response.status == 200) {
						setFattureMesiAnno(response.data.fattureMesiAnno);
						setImportiMesiAnno(response.data.importiMesiAnno);
					} else {
						genericAlert("Impossibile recuperare le fatture al momento. Riprova più tardi.");
					}
				})
				.catch(error => {
					handleAPIError(error, "recuperare le fatture");
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}


	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: deseleziono la fattura");
		}
		setIdFatturaSelezionata(null);
	}, [mese, anno, mostra, selectedPaziente, selectedProfessionista]);


	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: pagina contabilita aperta 2");
		}
		//fai una prima impostazione dell'altezza delle fullcalendar
		firstMonthWidthSet();
		// Aggiungi il listener per l'evento di ridimensionamento della finestra
		window.addEventListener('resize', () => { clearTimeout(resizeTimeout.current); resizeTimeout.current = setTimeout(updateMonthWidth, 500); });
	}, []);

	useEffect(() => {
		if (loggedUser != undefined && Object.keys(loggedUser).length > 0) {
			if (loggedUser.tipoUtente == "segretario") {
				setTabAperta(1);
			} else if (loggedUser.tipoUtente == "professionista") {
				setTabAperta(3);
			}
		}
	}, [loggedUser]);



	function firstMonthWidthSet() {
		if (monthsContainer.current != null && monthsContainer.current != undefined && monthsContainer.current.offsetWidth > 0) {
			updateMonthWidth();
			return;
		}
		setTimeout(firstMonthWidthSet, 50);
	}


	function updateMonthWidth() {
		if (monthsContainer.current != null && monthsContainer.current != undefined && monthsContainer.current.offsetWidth > 0) {
			let totWidth = monthsContainer.current.offsetWidth;
			let buttonsThatCanFit = Math.min(parseInt(totWidth / minMonthWidth), 12);
			setMonthWidth((totWidth - 1.0) / (buttonsThatCanFit + 0.0));
		}
	}


	function sedutaSenzaCertificazione(seduta) {
		return seduta.idCertificazione == null || seduta.idCertificazione == undefined;
	}

	//GIA MODIFICATO
	//imposta la ReqUrl per la datatable delle sedute
	useEffect(() => {
		if (loggedUser != undefined) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: cambio le sedute mostrate nella BackendDataTable (contabilita)");
			}
			if (selectedPaziente != undefined && selectedPaziente != 0) {
				setReqUrlSedute(process.env.REACT_APP_API_URL + "/sedute?noCertificazioni=true&unisciCognomeNome=true&idPaziente=" + selectedPaziente + "&mese=" + mese + "&anno=" + anno + "&soloSegnate=true&idProfessionista=" + loggedUser.user);
			} else {
				setReqUrlSedute(process.env.REACT_APP_API_URL + "/sedute?noCertificazioni=true&unisciCognomeNome=true&mese=" + mese + "&anno=" + anno + "&soloSegnate=true&idProfessionista=" + loggedUser.user);
			}
		}
	}, [loggedUser, selectedPaziente, selectedProfessionista, anno, mese, idFatturaSelezionata]);


	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: cambio le fatture mostrate nella BackendDataTable");
		}
		if (selectedPaziente != undefined && mostra == "Pazienti") {
			setReqUrlFatture(process.env.REACT_APP_API_URL + "/fatture?idPaziente=" + selectedPaziente + "&mese=" + mese + "&anno=" + anno);
			setReqUrlFattureAnteprima(process.env.REACT_APP_API_URL + "/fattureSimulate?idPaziente=" + selectedPaziente + "&mese=" + mese + "&anno=" + anno);
		} else if (selectedProfessionista != undefined && mostra == "Professionisti") {
			setReqUrlFatture(process.env.REACT_APP_API_URL + "/fatture?idProfessionista=" + selectedProfessionista + "&mese=" + mese + "&anno=" + anno);
			setReqUrlFattureAnteprima(process.env.REACT_APP_API_URL + "/fattureSimulate?idProfessionista=" + selectedProfessionista + "&mese=" + mese + "&anno=" + anno);
		} else if (selectedPaziente == undefined && selectedProfessionista == undefined && mostra == "Tutti") {
			setReqUrlFatture(process.env.REACT_APP_API_URL + "/fatture?mese=" + mese + "&anno=" + anno);
			setReqUrlFattureAnteprima(process.env.REACT_APP_API_URL + "/fattureSimulate?mese=" + mese + "&anno=" + anno);
		}
	}, [mese, anno, mostra, selectedPaziente, selectedProfessionista]);


	function certificazioneDaContabilizzare(certificazione) {
		return (loggedUser.tipoUtente == "professionista" && (certificazione.idPaziente == selectedPaziente || selectedPaziente == 0) && certificazione.dataAcquisto != null && parseInt(certificazione.dataAcquisto.substring(0, 4)) == anno && parseInt(certificazione.dataAcquisto.substring(5, 7)) == mese)
			||
			(loggedUser.tipoUtente == "segretario" && ((certificazione.idFattura == null && ((mostra == "Pazienti" && certificazione.idPaziente == selectedPaziente) ||
				(mostra == "Professionisti" && certificazione.idProfessionista == selectedProfessionista) ||
				(mostra == "Tutti")) &&
				certificazione.dataAcquisto != null && parseInt(certificazione.dataAcquisto.substring(0, 4)) == anno &&
				parseInt(certificazione.dataAcquisto.substring(5, 7)) == mese
			) || (certificazione.idFattura != null && certificazione.idFattura == idFatturaSelezionata)));
	}

	//GIA MODIFICATO
	//imposta correttamente il totale da contabilizzare (o già contabilizzato se hai selezionato una fattura) per le certificazioni
	//Seleziona le certificazioni da contabilizzare (o già contabilizzate se hai selezionato una fattura)
	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: calcolo totale per le certificazioni");
		}
		let prezzoTot = 0;
		let costoProfessionistaTot = 0;
		let certificazioniContabilizzateTemp = new Array(listaCertificazioni.length).fill(false);
		let nessunaCertificazioneContabilizzataTemp = true;
		for (let i = 0; i < listaCertificazioni.length; i++) {
			if (certificazioneDaContabilizzare(listaCertificazioni[i])) {
				//questa certificazione la metto
				certificazioniContabilizzateTemp[i] = true;
				nessunaCertificazioneContabilizzataTemp = false;
				prezzoTot += (listaCertificazioni[i].prezzo != undefined ? (listaCertificazioni[i].prezzo * 100) : 0);
				costoProfessionistaTot += (listaCertificazioni[i].costoProfessionista * 100);
			} else {
				//questa certificazione NON la metto
				certificazioniContabilizzateTemp[i] = false;
			}
		}
		setPrezziTotaliCertificazioni({
			prezzo: prezzoTot / 100.0,
			costoProfessionista: costoProfessionistaTot / 100.0
		});
		setCertificazioniContabilizzate(certificazioniContabilizzateTemp);
		setNessunaCertificazioneContabilizzata(nessunaCertificazioneContabilizzataTemp);
	}, [listaCertificazioni, selectedPaziente, selectedProfessionista, anno, mese, mostra, idFatturaSelezionata]);

	function aggiornaPercentualeCompletamento() {
		setTickIndex(tickIndex + 1);
		nodeReq.get(process.env.REACT_APP_API_URL + "/percenutaleCompletamentoFatture")
			.then(response => {
				if (response.status == 200) {
					setPercentualeCompletamento(response.data.completamento);
				} else {
					setPercentualeCompletamento(undefined);
				}
			})
			.catch(error => {
				setPercentualeCompletamento(undefined);
			});
	}

	useEffect(() => {
		if (tickIndex != undefined && percentualeCompletamento < 100) {
			setTimeout(aggiornaPercentualeCompletamento, 1000);
		}
	}, [tickIndex]);


	function generaFatture() {
		let idPazientiDaEscludere = [];
		for (let i = 0; i < pazientiConFIC.length; i++) {
			if (pazientiConFIC[i].notIncluded == true) {
				idPazientiDaEscludere.push(pazientiConFIC[i].idUtente);
			}
		}
		let idProfessionistiDaEscludere = [];
		for (let i = 0; i < professionistiContabilita.length; i++) {
			if (professionistiContabilita[i].notIncluded == true) {
				idProfessionistiDaEscludere.push(professionistiContabilita[i].idUtente);
			}
		}
		//TODO: chiama l'endpoint che genera tutte le fatture
		let reqBody = {
			mese: mese,
			anno: anno,
			idPazientiDaEscludere: idPazientiDaEscludere,
			idProfessionistiDaEscludere: idProfessionistiDaEscludere,
			fattureDiProva: generaFattureDiProva
		}

		setLoading(true);
		setPercentualeCompletamento(null);
		setTickIndex(0);
		nodeReq.post(process.env.REACT_APP_API_URL + "/fatture", reqBody)
			.then(response => {
				if (response.status == 200) {
					if (response.data == "nienteDaFatturare") {
						genericAlert("Non ci sono sedute o certificazioni da fatturare per il mese selezionato.");
					} else {
						setEsitoGenerazioneFatture(response.data);
						setShowModalEsitoGenerazione(true);
						setLeFattureSonoAggiornate(false);
						loadCertificazioni();
					}
				} else {
					genericAlert("Impossibile fatturare le sedute e le certificazioni al momento a causa di un errore. Riprova più tardi");
				}
			})
			.catch(error => {
				if (error.response != undefined && error.response.status == 503) {
					genericAlert("La generazione o la cancellazione delle fatture è già in corso su un altro dispositivo");
				} else {
					console.log(error);
					handleAPIError(error, "fatturare le sedute e le certificazioni");
				}
			})
			.finally(() => {
				setShowModalGeneraFatture(false);
				setLoading(false);
				setPercentualeCompletamento(null);
				setTickIndex(undefined);
			});
	}


	function cancellaFatture() {
		setLoading(true);
		setPercentualeCompletamento(null);
		setTickIndex(0);
		nodeReq.delete(process.env.REACT_APP_API_URL + "/fatture?mese=" + mese + "&anno=" + anno + "&cancellaFattureDiProva=" + cancellaFattureDiProva + "&cancellaFattureDefinitive=" + cancellaFattureDefinitive)
			.then(response => {
				if (response.status == 200) {
					let errorPrinted = false;
					for (let i = 0; i < response.data.risultatoFIC; i++) {
						if (response.data.risultatoFIC[i] == false) {
							console.log("RetryAfter: " + response.data.retryAfter);
							if (response.data.retryAfter != undefined && response.data.retryAfter != -1) {
								genericAlert("La cancellazione delle fatture è stata interrotta da FattureInCloud a causa del limite massimo di richieste raggiunto. Puoi riprendere la cancellazione delle fatture tra " + parseInt(response.data.retryAfter / 60) + ":" + (response.data.retryAfter % 60).toString().padStart(2, '0') + " minuti.");
							} else {
								genericAlert("Non è stato possibile eliminare tutte le fatture di questo mese. Puoi controllare quelle rimaste in questa pagina");
							}
							errorPrinted = true;
							break;
						}
					}
					for (let i = 0; i < response.data.risultatoDb; i++) {
						if (response.data.risultatoDb[i] == false) {
							console.log("RetryAfter: " + response.data.retryAfter);
							if (response.data.retryAfter != undefined && response.data.retryAfter != -1) {
								genericAlert("La cancellazione delle fatture è stata interrotta da FattureInCloud a causa del limite massimo di richieste raggiunto. Puoi riprendere la cancellazione delle fatture tra " + parseInt(response.data.retryAfter / 60) + ":" + (response.data.retryAfter % 60).toString().padStart(2, '0') + " minuti.");
							} else {
								genericAlert("Non è stato possibile eliminare tutte le fatture di questo mese. Puoi controllare quelle rimaste in questa pagina");
							}
							errorPrinted = true;
							break;
						}
					}
					if (errorPrinted == false) {
						genericAlert("Le fatture di questo mese sono state tutte eliminate correttamente");
					}
					setLeFattureSonoAggiornate(false);
					loadCertificazioni();
				} else {
					genericAlert("Impossibile eliminare le fatture e le certificazioni del mese selezionato. Riprova più tardi.");
				}
			})
			.catch(error => {
				if (error.response != undefined && error.response.status == 503) {
					genericAlert("La generazione o la cancellazione delle fatture è già in corso su un altro dispositivo");
				} else {
					handleAPIError(error, "eliminare le fatture e le certificazioni del mese selezionato");
				}
			})
			.finally(() => {
				setShowModalCancellaFatture(false);
				setLoading(false);
				setPercentualeCompletamento(null);
				setTickIndex(undefined);
			});
	}


	function handleFatturaSingleClick(fattura) {
		setIdFatturaSelezionata(fattura.idFattura);
	}

	function getSubDataTableReqUrlFattura(fattura) {
		return process.env.REACT_APP_API_URL + "/seduteCertificazioneFatturaSubDataTable?idFattura=" + fattura.idFattura;
	}

	function getSubDataTableReqUrlFatturaAnteprima(fattura) {
		return process.env.REACT_APP_API_URL + "/seduteCertificazioneFatturaAnteprimaSubDataTable?idCertificazione=" + fattura.id + "&mese=" + mese + "&anno=" + anno + "&fatturaPer=" + fattura.fatturaPer + "&idPaziente=" + fattura.idPaziente + "&idProfessionista=" + fattura.idProfessionista;
	}


	function mostraPdfFattura(fattura) {
		let documentId = fattura.idDocumentoFIC;
		setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/linkPdf?documentId=" + documentId)
			.then(response => {
				if (response.status == 200) {
					let link = response.data.link;
					window.open(link, '_blank');
				} else {
					genericAlert("Impossibile visualizzare il file PDF della fattura. Potrebbe essere stata eliminata da FattureInCloud");
				}
			})
			.catch(error => {
				genericAlert("Impossibile visualizzare il file PDF della fattura. Potrebbe essere stata eliminata da FattureInCloud");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		if (isShowModalGeneraFatture == true) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: modal genera/cancella fatture aperto");
			}
			setPazientiNoFIC(null);
			setPazientiConFIC([]);
			//ottieni i pazienti non associati a FIC
			setLoading(true);
			nodeReq.get(process.env.REACT_APP_API_URL + "/pazientiNoFICConFIC")
				.then(response => {
					if (response.status == 200) {
						setPazientiNoFIC(response.data.pazientiNoFIC);
						let pazientiConFICTemp = response.data.pazientiConFIC;
						for (let i = 0; i < pazientiConFICTemp.length; i++) {
							pazientiConFICTemp[i].id = pazientiConFICTemp[i].idUtente;
							pazientiConFICTemp[i].included = (pazientiConFICTemp[i].includiInFatturazione == true);
							pazientiConFICTemp[i].notIncluded = !pazientiConFICTemp[i].included;
						}
						setPazientiConFIC(pazientiConFICTemp);
					} else {
						genericAlert("Impossibile recuperare i pazienti non associati a FattureInCloud al momento, riprova più tardi");
						setPazientiNoFIC(undefined);
						setPazientiConFIC([]);
					}
				})
				.catch(error => {
					console.log(error);
					handleAPIError(error, "recuperare i pazienti non associati a FattureInCloud");
				})
				.finally(() => {
					setLoading(false);
				});
			setProfessionistiContabilita([]);
			//ottieni i pazienti non associati a FIC
			setLoading(true);
			nodeReq.get(process.env.REACT_APP_API_URL + "/professionistiPerContabilita")
				.then(response => {
					if (response.status == 200) {
						setProfessionistiContabilita(response.data.professionisti);
						let professionistiTemp = response.data.professionisti;
						for (let i = 0; i < professionistiTemp.length; i++) {
							professionistiTemp[i].id = professionistiTemp[i].idUtente;
							professionistiTemp[i].included = (professionistiTemp[i].includiInFatturazione == true);
							professionistiTemp[i].notIncluded = !professionistiTemp[i].included;
						}
						setProfessionistiContabilita(professionistiTemp);
					} else {
						genericAlert("Impossibile recuperare i professionisti al momento, riprova più tardi");
						setProfessionistiContabilita([]);
					}
				})
				.catch(error => {
					console.log(error);
					handleAPIError(error, "recuperare i professionisti");
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [isShowModalGeneraFatture]);

	useEffect(() => {
		let escludiDisabledPazientiTemp = true;
		let nonEscludereDisabledPazientiTemp = true;
		let breakFor = false;
		if (Array.isArray(selectedPazientiConFIC)) {
			for (let i = 0; i < selectedPazientiConFIC.length; i++) {
				for (let j = 0; j < pazientiConFIC.length; j++) {
					//se il paziente è selezionato...
					if (selectedPazientiConFIC.includes(pazientiConFIC[j].idUtente.toString())) {
						//se il paziente selezionato è incluso e non hai ancora abilitato il pulsante escludi
						if (escludiDisabledPazientiTemp = true && pazientiConFIC[j].included == true && pazientiConFIC[j].notIncluded == false) {
							console.log("Riabilito il tasto escludi per questo paziente: ");
							console.log(pazientiConFIC[j]);
							escludiDisabledPazientiTemp = false;
							//se il paziente selezionato non è incluso e non hai ancora abilitato il pulsante non escludere
						} else if (nonEscludereDisabledPazientiTemp = true && pazientiConFIC[j].included == false && pazientiConFIC[j].notIncluded == true) {
							nonEscludereDisabledPazientiTemp = false;
						}
					}
					if (escludiDisabledPazientiTemp == false && nonEscludereDisabledPazientiTemp == false) {
						breakFor = true;
						break;
					}
				}
				if (breakFor) {
					break;
				}
			}
		}
		console.log("EscludiDisabled: " + escludiDisabledPazientiTemp);
		console.log("NonEscludereDisabled: " + nonEscludereDisabledPazientiTemp);
		setEscludiDisabledPazienti(escludiDisabledPazientiTemp);
		setNonEscludereDisabledPazienti(nonEscludereDisabledPazientiTemp);
	}, [selectedPazientiConFIC]);

	useEffect(() => {
		let escludiDisabledProfessionistiTemp = true;
		let nonEscludereDisabledProfessionistiTemp = true;
		let breakFor = false;
		if (Array.isArray(selectedProfessionistiConFIC)) {
			for (let i = 0; i < selectedProfessionistiConFIC.length; i++) {
				for (let j = 0; j < professionistiContabilita.length; j++) {
					//se il paziente è selezionato...
					if (selectedProfessionistiConFIC.includes(professionistiContabilita[j].idUtente.toString())) {
						//se il paziente selezionato è incluso e non hai ancora abilitato il pulsante escludi
						if (escludiDisabledProfessionistiTemp = true && professionistiContabilita[j].included == true && professionistiContabilita[j].notIncluded == false) {
							console.log("Riabilito il tasto escludi per questo paziente: ");
							console.log(professionistiContabilita[j]);
							escludiDisabledProfessionistiTemp = false;
							//se il paziente selezionato non è incluso e non hai ancora abilitato il pulsante non escludere
						} else if (nonEscludereDisabledProfessionistiTemp = true && professionistiContabilita[j].included == false && professionistiContabilita[j].notIncluded == true) {
							nonEscludereDisabledProfessionistiTemp = false;
						}
					}
					if (escludiDisabledProfessionistiTemp == false && nonEscludereDisabledProfessionistiTemp == false) {
						breakFor = true;
						break;
					}
				}
				if (breakFor) {
					break;
				}
			}
		}
		console.log("EscludiDisabled: " + escludiDisabledProfessionistiTemp);
		console.log("NonEscludereDisabled: " + nonEscludereDisabledProfessionistiTemp);
		setEscludiDisabledProfessionisti(escludiDisabledProfessionistiTemp);
		setNonEscludereDisabledProfessionisti(nonEscludereDisabledProfessionistiTemp);
	}, [selectedProfessionistiConFIC]);


	function inclusionePazienti(include) {
		let pazientiConFICTemp = pazientiConFIC.slice();
		for (let i = 0; i < pazientiConFICTemp.length; i++) {
			if (selectedPazientiConFIC.includes(pazientiConFICTemp[i].id.toString())) {
				if (!include && pazientiConFICTemp[i].included == true && pazientiConFICTemp[i].notIncluded == false) {
					pazientiConFICTemp[i].included = false; pazientiConFICTemp[i].notIncluded = true;
				} else if (include && pazientiConFICTemp[i].included == false && pazientiConFICTemp[i].notIncluded == true) {
					pazientiConFICTemp[i].included = true; pazientiConFICTemp[i].notIncluded = false;
				}
			}
		}
		setPazientiConFIC(pazientiConFICTemp);
		setSelectedPazientiConFIC([]);
	}

	function getPazienteText(paziente) {
		return paziente.paziente;
	}

	function inclusioneProfessionisti(include) {
		let professionistiContabilitaTemp = professionistiContabilita.slice();
		for (let i = 0; i < professionistiContabilitaTemp.length; i++) {
			if (selectedProfessionistiConFIC.includes(professionistiContabilitaTemp[i].id.toString())) {
				if (!include && professionistiContabilitaTemp[i].included == true && professionistiContabilitaTemp[i].notIncluded == false) {
					professionistiContabilitaTemp[i].included = false; professionistiContabilitaTemp[i].notIncluded = true;
				} else if (include && professionistiContabilitaTemp[i].included == false && professionistiContabilitaTemp[i].notIncluded == true) {
					professionistiContabilitaTemp[i].included = true; professionistiContabilitaTemp[i].notIncluded = false;
				}
			}
		}
		setProfessionistiContabilita(professionistiContabilitaTemp);
		setSelectedProfessionistiConFIC([]);
	}

	function getProfessionistaText(professionista) {
		return professionista.professionista;
	}

	//somma due importi salvati come stringa  (ES: "25.60" + "23.90")
	//i due importi devono essere salvati così
	function sommaImporti(imp1, imp2) {
		console.log("Importo1: " + imp1 + ", Importo2: " + imp2);
		let intImp1 = parseInt(imp1.toString().substring(0, imp1.toString().length - 3));
		let intImp2 = parseInt(imp2.toString().substring(0, imp2.toString().length - 3));
		let decImp1 = parseInt(imp1.toString().substring(imp1.toString().length - 2, imp1.toString().length));
		let decImp2 = parseInt(imp2.toString().substring(imp2.toString().length - 2, imp2.toString().length));
		let toReturn = intImp1 + intImp2 + (decImp1 / 100.0) + (decImp2 / 100.0);
		console.log("Importo1: " + imp1 + ", Importo2: " + imp2 + ", restituisco " + toReturn + " come un " + typeof toReturn);
		return toReturn;
	}

	function aggiornaStatiPagamentiFatture() {
		setLoading(true);
		setTickIndexScansioneFatture(0);
		nodeReq.get(process.env.REACT_APP_API_URL + '/aggiornaStatiPagamentiFatture')
			.then(response => {
				if (response.status == 200) {
					/*
					let pagate = 0;
					let nonPagate = 0;
					for (let i = 0; i < response.data.result.length; i++) {
						if (response.data.result[i].status == "pagata") {
							pagate++;
						} else if (response.data.result[i].status == "non pagata") {
							nonPagate++;
						}
					}
					*/
					//genericAlert("Aggiornamento completato. Sono state trovate " + pagate + " nuove fatture pagate e " + nonPagate + " ancora da pagare rispetto all'ultima scansione. " + (response.data.length - pagate - nonPagate > 0 ? "Per " + (response.data.length - pagate - nonPagate) + " fatture non è stato possibile verificare lo stato di pagamento a causa delle restrizioni di Fatture In Cloud. Puoi rieseguire l'aggiornamento più tardi (tra "+parseInt(response.data.retryAfter / 60) + ":" + (response.data.retryAfter % 60).toString().padStart(2, '0') + " minuti." : ""));
					genericAlert("L'aggiornamento è stato completato");
					//console.log(response.data.result);
				} else {
					genericAlert("Impossibile aggiornare gli stati dei pagamenti delle fatture al momento a causa di un errore. Riprova più tardi");
				}
			})
			.catch(error => {
				if (error.response != undefined && error.response.status == 503) {
					genericAlert("La scansione delle fatture è già in corso su un altro dispositivo");
				} else {
					//handleAPIError(error, "controllare i pagamenti delle fatture");
					genericAlert("Impossibile controllare i pagamenti delle fatture al momento. La causa potrebbe essere un errore imprevisto o il raggiungimento del limite di richieste di FattureInCloud. Riprovare più tardi. Se il problema persiste, contattare il supporto.");
				}
			})
			.finally(() => {
				setLoading(false);
				loadDataOraUltimoControlloPagamenti();
				setTickIndexScansioneFatture(null);
			});
	}
	return (
		<div style={{ display: pageViewed === pages.Contabilita ? 'block' : 'none' }}>
			<Heading title="Contabilità" iconName="euro-sign" />
			{/*Div contenente il pulsante "Genera fatture*/}
			{loggedUser.tipoUtente == "segretario" &&
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body d-flex flex-row flex-wrap">
								<div className="p-1">
									<div>
										<button onClick={() => { setShowModalGeneraFatture(true); }} className="btn btn-primary btn-lg m-1">Genera fatture</button>
										<button onClick={() => { setShowModalCancellaFatture(true); setCancellaFattureDiProva(true); setCancellaFattureDefinitive(false); }} className="btn btn-danger btn-lg m-1">Cancella fatture</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			<div className="row">
				<div className="col-lg-3">
					<div className="card">
						<div className="card-header">
							<h5 className="card-title">Mostra situazione contabile di</h5>
						</div>
						<div className="card-body">
							<div className="form-group mb-1">
								{loggedUser.tipoUtente != "professionista" &&
									<>
										<button type="button" style={{ borderRadius: "5px 0px 0px 5px" }} className={"btn my-1 mx-0 " + (mostra == "Tutti" ? "btn-primary" : "btn-light")} onClick={() => { setMostra("Tutti"); setSelectedProfessionista(loggedUser.tipoUtente == "professionista" ? loggedUser.user : undefined); setSelectedPaziente(undefined); }}>Tutti</button>
										<button type="button" style={{ borderRadius: "0px 0px 0px 0px" }} className={"btn my-1 mx-0 " + (mostra == "Pazienti" ? "btn-primary" : "btn-light")} onClick={() => { setMostra("Pazienti"); setSelectedProfessionista(loggedUser.tipoUtente == "professionista" ? loggedUser.user : undefined); setSelectedPaziente(undefined); }}>Paziente</button>
										<button type="button" style={{ borderRadius: "0px 5px 5px 0px" }} className={"btn my-1 mx-0 " + (mostra == "Professionisti" ? "btn-primary" : "btn-light")} onClick={() => { setMostra("Professionisti"); setSelectedProfessionista(loggedUser.tipoUtente == "professionista" ? loggedUser.user : undefined); setSelectedPaziente(undefined); }}>Professionista</button>
									</>
								}
							</div>
							<div className="form-group">
								<SelettoreProfessionistaPaz paziente={true} show={mostra == "Pazienti"} allowAll={loggedUser.tipoUtente == "professionista" ? true : false} selected={selectedPaziente} setSelected={setSelectedPaziente} updated={pazientiUpdated} setUpdated={setPazientiUpdated} />
								<SelettoreProfessionistaPaz paziente={false} show={mostra === "Professionisti"} allowAll={false} selected={selectedProfessionista} setSelected={setSelectedProfessionista} updated={professionistiUpdated} setUpdated={setProfessionistiUpdated} />
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-9">
					<div className="card">
						<div className="card-header d-flex flex-row">
							<div className="flex-grow-1">
								<h5 className="card-title">Mese</h5>
							</div>
							<div>
								<input type="number" className="form-control" style={{ width: "96px" }} value={anno} onChange={(event) => { setAnno(isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)); }} />
							</div>
						</div>
						<div className="card-body">
							<div ref={monthsContainer} style={{ width: "100%" }}>
								{mesi.map((nomeMese, index) => {
									return <div key={index} style={{ width: monthWidth + "px", padding: "4px", display: "inline-block" }}><button style={{ width: "100%", overflow: "hidden" }} className={"btn " + (mese == index + 1 ? "btn-primary" : "btn-light")} onClick={() => { setMese(index + 1); }}><p className="mb-1 text-nowrap">{nomeMese.substring(0, 3)}</p>{loggedUser.tipoUtente == "segretario" && <p className="mb-1 text-nowrap"><FeatherIcon iconName="file" addedClassName="align-middle" />&nbsp;{fattureMesiAnno[index]}</p>}<p className="mb-1 text-nowrap">{importoConPuntoEVirgola(importiMesiAnno[index])}</p></button></div>;
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Riepilogo*/}
			<div className="row">
				<div className="col-md">
					<div className="card">
						<div className="card-header">
							<h5 className="card-title">Riepilogo</h5>
						</div>
						{selezioneCorretta && importiRiepilogo.length == 4 &&
							<div className="card-body">
								{loggedUser.tipoUtente == "segretario" &&
									<>
										{props.abilitaCertificazioni == true && <p>{mostra == "Professionisti" && "Totale prestazioni professionista fatturate:"}{mostra == "Pazienti" && "Totale prestazioni professionisti fatturate:"}{mostra == "Tutti" && "Totale prestazioni professionisti fatturate:"} (<b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[1].costoProfessionistaTot)}</b> per le sedute + <b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[3].costoProfessionistaTot)}</b> per le certificazioni)</p>}
										{props.abilitaCertificazioni == false && <p>{mostra == "Professionisti" && "Totale prestazioni professionista fatturate:"}{mostra == "Pazienti" && "Totale prestazioni professionisti fatturate:"}{mostra == "Tutti" && "Totale prestazioni professionisti fatturate:"} <b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[1].costoProfessionistaTot)}</b></p>}
										{props.abilitaCertificazioni == true && <p>{mostra == "Professionisti" && "Totale prestazioni professionista da fatturare:"}{mostra == "Pazienti" && "Totale prestazioni professionisti da fatturare:"}{mostra == "Tutti" && "Totale prestazioni professionisti da fatturare:"} (<b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[0].costoProfessionistaTot)}</b> per le sedute + <b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[2].costoProfessionistaTot)}</b> per le certificazioni)</p>}
										{props.abilitaCertificazioni == false && <p>{mostra == "Professionisti" && "Totale prestazioni professionista da fatturare:"}{mostra == "Pazienti" && "Totale prestazioni professionisti da fatturare:"}{mostra == "Tutti" && "Totale prestazioni professionisti da fatturare:"} <b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[0].costoProfessionistaTot)}</b></p>}
										<p>{mostra == "Professionisti" && "Totale fatture emesse ai pazienti:"}{mostra == "Pazienti" && "Totale fatture emesse al paziente:"}{mostra == "Tutti" && "Totale fatture emesse ai pazienti:"} (<b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[1].prezzoTot)}</b> per le sedute + <b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[3].prezzoTot)}</b> per le certificazioni)</p>
										<p>{mostra == "Professionisti" && "Totale fatture da emettere ai pazienti:"}{mostra == "Pazienti" && "Totale fatture da emettere al paziente:"}{mostra == "Tutti" && "Totale fatture da emettere ai pazienti:"} (<b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[0].prezzoTot)}</b> per le sedute + <b className="text-primary">{importoConPuntoEVirgola(importiRiepilogo[2].prezzoTot)}</b> per le certificazioni)</p>
									</>
								}
								{loggedUser.tipoUtente == "professionista" &&
									<>
										{props.abilitaCertificazioni == true && <p>Totale prestazioni professionista: (<b className="text-primary">{importoConPuntoEVirgola(sommaImporti(importiRiepilogo[0].costoProfessionistaTot, importiRiepilogo[1].costoProfessionistaTot))}</b> per le sedute + <b className="text-primary">{importoConPuntoEVirgola(sommaImporti(importiRiepilogo[2].costoProfessionistaTot, importiRiepilogo[3].costoProfessionistaTot))}</b> per le certificazioni)</p>}
										{props.abilitaCertificazioni == false && <p>Totale prestazioni professionista: <b className="text-primary">{importoConPuntoEVirgola(sommaImporti(importiRiepilogo[0].costoProfessionistaTot, importiRiepilogo[1].costoProfessionistaTot))}</b></p>}
									</>
								}
							</div>
						}
						{!selezioneCorretta &&
							<div className="card-body">
								{loggedUser.tipoUtente == "segretario" &&
									<p className="text-danger">Seleziona un paziente, un professionista oppure tutti per vedere il riepilogo</p>
								}
								{loggedUser.tipoUtente == "professionista" &&
									<p className="text-danger">Seleziona un paziente oppure tutti per vedere il riepilogo</p>
								}
							</div>
						}
					</div>
				</div>
			</div>
			{loggedUser.tipoUtente == "segretario" &&
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header">
								<div className="d-flex flex-row justify-content-between">
									<div>
										<h5 className="card-title">Controllo pagamenti delle fatture</h5>
									</div>
									<div>
										<p className="mb-0">Ultimo controllo: {ultimoControllo != undefined ? ultimoControllo : "--"}</p>
									</div>
								</div>
							</div>
							<div className="card-body">
								<p>Il controllo dei pagamenti delle fatture, viene eseguito sulle fatture non pagate di tutti i mesi inserite dal gestionale.</p>
								<div>
									<APICallButton buttonOnClick={aggiornaStatiPagamentiFatture} buttonText={"Avvia controllo" + (tickIndexScansioneFatture != null && percentualeCompletamentoScansioneFatture != null && percentualeCompletamentoScansioneFatture != undefined && percentualeCompletamentoScansioneFatture != 100 ? " (" + percentualeCompletamentoScansioneFatture.toFixed(2) + "%)" : "")} buttonClassName={"btn btn-secondary btn-lg m-1"} nomeProcedura={"il controllo dei pagamenti delle fatture"}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			{/* sezione a tabs*/}
			<div className="mainElementHeightContainer">
				<div className="backendDataTableContainer">
					<div>
						<div className="btn-group btn-group-lg m-1 d-inline-block" role="group" aria-label="Large button group">
							{abilitaTab1 && <button type="button" class={"btn " + (tabAperta == 1 ? "btn-primary" : "btn-light")} onClick={() => { setTabAperta(1); }}>Fatture emesse</button>}
							{abilitaTab2 && <button type="button" class={"btn " + (tabAperta == 2 ? "btn-primary" : "btn-light")} onClick={() => { setTabAperta(2); }}>Fatture da emettere</button>}
							{abilitaTab3 && <button type="button" class={"btn " + (tabAperta == 3 ? "btn-primary" : "btn-light")} onClick={() => { setTabAperta(3); }}>Sedute del mese</button>}
							{abilitaTab4 && <button type="button" class={"btn " + (tabAperta == 4 ? "btn-primary" : "btn-light")} onClick={() => { setTabAperta(4); }}>Certificazioni del mese</button>}
						</div>
					</div>
					{tabAperta == 1 && (abilitaTab1 && selezioneCorretta) &&
						
							<BackendDataTable fileName={"Fatture emesse " + mesi[mese - 1] + " " + anno} colorizeSelectedRow={true} rowSingleClickFunction={handleFatturaSingleClick} loadInsidePage={pages.Contabilita} tableId={"fattureTable"} reqUrl={reqUrlFatture} show={true} columnsToExclude={columnsToExcludeFatture} functionsOnColumns={functionsOnColumnsFatture} columnsDataTypes={columnsDataTypesFatture} actionButtonFunction={loggedUser.tipoUtente == "professionista" ? undefined : mostraPdfFattura} actionButtonName={loggedUser.tipoUtente == "professionista" ? undefined : "Apri il PDF"} dataIsUpdated={leFattureSonoAggiornate} setDataIsUpdated={setLeFattureSonoAggiornate} subDataTableReqUrl={getSubDataTableReqUrlFattura} subDataTableTitle={"Sedute o certificazione fatturate"} columnsColorsFunctions={columnsColorsFunctionsFatture}/>
						
					}
					{tabAperta == 2 && (abilitaTab2 && selezioneCorretta) &&

							<BackendDataTable fileName={"Fatture da emettere " + mesi[mese - 1] + " " + anno} loadInsidePage={pages.Contabilita} tableId={"fattureAnteprimaTable"} reqUrl={reqUrlFattureAnteprima} show={true} columnsToExclude={columnsToExcludeFatture} functionsOnColumns={functionsOnColumnsFatture} columnsDataTypes={{ "totaleFattura": "price" }} pricesColumns={["prezzo"]} subDataTableReqUrl={getSubDataTableReqUrlFatturaAnteprima} subDataTableTitle={"Sedute o certificazione da fatturare"} />
						
					}
					{tabAperta == 3 && (abilitaTab3 && selezioneCorretta) &&
						
							<BackendDataTable fileName={"Sedute effettuate " + mesi[mese - 1] + " " + anno} rowClickFunction={() => { }} loadInsidePage={pages.Contabilita} tableId={"seduteEffettuateTable"} reqUrl={reqUrlSedute} show={true} columnsToExclude={columnsToExcludeSeduteEffettuate} functionsOnColumns={functionsOnColumnsSedute} columnsDataTypes={columnsDataTypesSedute} mustAddPriceToTotal={sedutaSenzaCertificazione} />
						
					}
					{tabAperta == 4 && (abilitaTab4 && selezioneCorretta) &&
						<div>
							<div className="overflow-y-scroll">
								{!nessunaCertificazioneContabilizzata &&
									<>
										<ul>
											{listaCertificazioni.map((item, index) => {
												if (certificazioniContabilizzate[index] == true) {
													return <li key={index}><b>Certificazione: </b>{item.nomeServizio} {item.descrizioneServizio.length > 0 ? "(" + item.descrizioneServizio + ")" : ""} {item.descrizione.length > 0 ? "(" + item.descrizione + ")" : ""} <b>Paziente: </b>{item.nomePaziente} {item.cognomePaziente}   <b>Professionista: </b>{item.nomeProfessionista} {item.cognomeProfessionista} {loggedUser.tipoUtente == "segretario" && <><b>Prezzo: </b>{importoConPuntoEVirgola(item.prezzo)}</>}   <b>Costo professionista: </b>{importoConPuntoEVirgola(item.costoProfessionista)}  <b>Data di acquisto: </b>{dataFormatoItaliano(item.dataAcquisto)}   <b>Data di conclusione: </b>{dataFormatoItaliano(item.dataConclusione)}</li>;
												} else {
													return <></>
												}
											})}
										</ul>
									</>
								}
								{nessunaCertificazioneContabilizzata &&
									<p>Nessuna certificazione nel mese selezionato</p>
								}
							</div>
						</div>
					}
					{!selezioneCorretta &&
						<>
							{loggedUser.tipoUtente == "segretario" &&
								<p className="text-danger">Seleziona un paziente, un professionista oppure tutti</p>
							}
							{loggedUser.tipoUtente == "professionista" &&
								<p className="text-danger">Seleziona un paziente oppure tutti</p>
							}
						</>
					}
				</div>
			</div>
			{/*Fine delle card. Iniziano le modals*/}
			<ModalPrompt modalSize={'xl'} title={"Genera le fatture per " + mesi[mese - 1] + " " + anno} showmodal={isShowModalGeneraFatture} closemodal={() => { setShowModalGeneraFatture(false); }} okButtonColor={"primary"} okButtonText={"Genera le fatture"} okButtonFunction={generaFatture} nomeProcedura={"la generazione delle fatture"}>
				<p>Confermi di voler generare le fatture <b className="text-primary">{generaFattureDiProva ? "di prova" : "definitive"}</b> per <b className="text-primary">{mesi[mese - 1]} {anno}</b>?</p>
				{isShowModalGeneraFatture &&
					<>
						<div className="row">
							<div className="col-md">
								<PazientiNoFIC pazientiNoFIC={pazientiNoFIC} />
							</div>
							<div className="col-md">
								<SearchAndSelect title={"Pazienti inclusi"} viewedVariable={"included"} elements={pazientiConFIC} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedPazientiConFIC} setSelectedOptions={setSelectedPazientiConFIC} hideBottomText={true} getElementText={getPazienteText} />
								{/*Pulsanti Assegna/Rimuovi*/}
								<section>
									<button className="btn btn-secondary m-1" disabled={escludiDisabledPazienti} onClick={() => { inclusionePazienti(false); }} ><FeatherIcon iconName="chevron-down" addedClassName="" /> Escludi</button>
									<button className="btn btn-secondary m-1" disabled={nonEscludereDisabledPazienti} onClick={() => { inclusionePazienti(true); }}><FeatherIcon iconName="chevron-up" addedClassName="" /> Non escludere</button>
								</section>
								<SearchAndSelect title={"Pazienti da escludere"} viewedVariable={"notIncluded"} elements={pazientiConFIC} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedPazientiConFIC} setSelectedOptions={setSelectedPazientiConFIC} hideBottomText={true} getElementText={getPazienteText} />
							</div>
							<div className="col-md">
								<SearchAndSelect title={"Professionisti inclusi"} viewedVariable={"included"} elements={professionistiContabilita} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedProfessionistiConFIC} setSelectedOptions={setSelectedProfessionistiConFIC} hideBottomText={true} getElementText={getProfessionistaText} />
								{/*Pulsanti Assegna/Rimuovi*/}
								<section>
									<button className="btn btn-secondary m-1" disabled={escludiDisabledProfessionisti} onClick={() => { inclusioneProfessionisti(false); }} ><FeatherIcon iconName="chevron-down" addedClassName="" /> Escludi</button>
									<button className="btn btn-secondary m-1" disabled={nonEscludereDisabledProfessionisti} onClick={() => { inclusioneProfessionisti(true); }}><FeatherIcon iconName="chevron-up" addedClassName="" /> Non escludere</button>
								</section>
								<SearchAndSelect title={"Professionisti da escludere"} viewedVariable={"notIncluded"} elements={professionistiContabilita} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedProfessionistiConFIC} setSelectedOptions={setSelectedProfessionistiConFIC} hideBottomText={true} getElementText={getProfessionistaText} />
							</div>
						</div>
						<div className="d-flex flex-column p-1">
							<div className="ms-1">
								<label>Su FattureInCloud, genera</label>
							</div>
							<div class="btn-group btn-group-lg m-1" role="group" aria-label="Large button group">
								<button type="button" class={"btn " + (generaFattureDiProva ? "btn-primary" : "btn-light")} onClick={() => { setGeneraFattureDiProva(true); }}>Fatture di prova</button>
								<button type="button" class={"btn " + (!generaFattureDiProva ? "btn-primary" : "btn-light")} onClick={() => { setGeneraFattureDiProva(false); }}>Fatture definitive</button>
							</div>
						</div>
						<ProgressBar percentualeCompletamento={percentualeCompletamento} />
					</>
				}
			</ModalPrompt>
			<ModalPrompt title={"Cancella le fatture per " + mesi[mese - 1] + " " + anno} showmodal={isShowModalCancellaFatture} closemodal={() => { setShowModalCancellaFatture(false); }} okButtonColor={"danger"} okButtonText={"Cancella le fatture"} okButtonFunction={cancellaFatture} okButtonDisabled={!cancellaFattureDiProva && !cancellaFattureDefinitive} nomeProcedura={"la cancellazione delle fatture"}>
				<p>Confermi di voler cancellare le fatture per <b className="text-primary">{mesi[mese - 1]} {anno}</b>?</p>
				<div>
					<input id="cancellaFattureDiProvaCheck" type="checkbox" className="form-check-input m-1" checked={cancellaFattureDiProva} onChange={(event) => { setCancellaFattureDiProva(event.target.checked); }} />
					<label htmlFor="cancellaFattureDiProvaCheck">Cancella fatture di prova</label>
				</div>
				<div>
					<input id="cancellaFattureDefinitiveCheck" type="checkbox" className="form-check-input m-1" checked={cancellaFattureDefinitive} onChange={(event) => { setCancellaFattureDefinitive(event.target.checked); }} />
					<label htmlFor="cancellaFattureDefinitiveCheck">Cancella fatture definitive</label>
				</div>
				{!cancellaFattureDiProva && !cancellaFattureDefinitive &&
					<p className="text-danger">Selezionare almeno una tipologia di fatture</p>
				}
				{cancellaFattureDefinitive &&
					<p className="bg-warning p-1" style={{ borderRadius: "0.25rem" }}><b>ATTENZIONE!</b> stai per cancellare le fatture definitive</p>
				}
				<ProgressBar percentualeCompletamento={percentualeCompletamento} />
			</ModalPrompt>
			<EsitoGenerazioneFattureModal showmodal={isShowModalEsitoGenerazione} closemodal={() => { setShowModalEsitoGenerazione(false); }} esito={esitoGenerazioneFatture} mese={mese} mesi={mesi} anno={anno} />
		</div >
	);
}

export default Contabilita;